import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthApi, ConfigApi, UserApi, Helpers } from '../service/index';
import CssBaseline from '@mui/material/CssBaseline';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/style.css';

const drawerWidth = 240;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
}
);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },

}));
export default function DashboardApp(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [userinfo, setUserInfo] = useState({});
  const [userData, setUserData] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState();

  const history = useNavigate();

  useEffect(() => {
    let isLogin = Helpers.checkLogin();
    if (!isLogin) {
      Helpers.logout(history);
      return false;
    }
    const userInfoString = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(userInfoString);
    getLocation();
    setLocationId(userInfo.office_location)
    getUserList(userInfo.office_location, "YES");
    setUserId(userInfo.id);
    getUserDetail(userInfo.id);
  }, []);

  const getUserDetail = (userId) => {
    setLoading(true);
    UserApi.user(userId).then(
      (resp) => {
        if (resp.status === 200) {
          setUserData(resp.data.response.usersdetails);
          setLoading(false);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
          setLoading(false);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
      setLoading(false);
    });
  };


  const getLocation = async () => {

    await ConfigApi.location().then(
      (resp) => {
        if (resp.status === 200) {
          setLocationData(resp.data.response);
          console.log("Location data", resp.data.response);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      /* alert(error);*/
      Helpers.error401(error);
    });
  };

  const getUserList = async (id, onload) => {
    await UserApi.userlistwithLocationId(id).then(
      (resp) => {
        if (resp.status === 200) {
          setUserListData(resp.data.response);
          console.log("userdata", resp.data.response);
          if (resp.data.response.length > 0 && onload == "NO") {
            setUserId(resp.data.response[0].id);
            getUserDetail(resp.data.response[0].id);
          }
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      /* alert(error);*/
      Helpers.error401(error);
    });
  };

  const handleUserSelect = (event) => {
    const selectedUserId = event.target.value;
    setUserId(event.target.value);
    getUserDetail(event.target.value);
  };


  const selectLocation = async (event) => {
    setLocationId(event.target.value);
    await getUserList(event.target.value, "NO");
  };

  const editProfile = (userDetails) => {
    navigate('/dashboard/register', { replace: true, state: userDetails.id });
  };

  const fallbackImage = '/avatar.png';

  function toTitleCase(text) {
    if (text) {
      return text.replace(/\w\S*/g, function (word) {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
      });
    } else {
      return '';
    }
  }

  return (
    <div style={{ "padding": "0 30px", }}>

      <div className="bgwhite2" >

        <Grid container >
          <Grid xs={12} md={3} sm={3}>
            <div className={Helpers.cmAccess('ATTENDENCE_LOCATION_LIST')}>
              <p className="textyu">Location</p>
              <select style={{ "width": "260px", "height": "42px", "fontSize": "16px", "marginBottom": "28px", "border": "2px solid #dbdbdb", "borderRadius": "4px", "paddingLeft": "11px" }} className="formControl" onChange={selectLocation} value={locationId}>
                {locationData.length > 0 && locationData?.map((item, index) => (
                  <option value={item.office_location_id} >{item.name}</option>))
                }
              </select>
            </div>
          </Grid>
          <Grid xs={12} md={6} sm={3}>
            <div className="dataemp datemap3">
              <div className={Helpers.cmAccess('DASHBOARD_EMPLOYEE')}>
                <p className="textyu">Employee Name</p>
                <select style={{ "width": "260px", "height": "42px", "fontSize": "16px", "marginBottom": "28px", "border": "2px solid #dbdbdb", "borderRadius": "4px", "paddingLeft": "11px", }} onChange={handleUserSelect} value={userId}>
                  {userListData.map(item => (
                    <option value={item.id}>
                      {item.first_name} {item.last_name} ( {item.user_code} )
                    </option>
                  ))}
                </select>
              </div>

            </div>
          </Grid>
          <Grid xs={12} md={3} sm={3}>
            <div className={Helpers.cmAccess('DASHBOARD_ADD_EMPLOYEE')}>
              <Box style={{ "padding": "10px 10px", "float": "right", "background": "#fff", "border": "1px solid #cdcdcd", "borderRadius": "4px", "fontSize": "12px", marginTop: "28px" }} component="div" sx={{ display: 'inline' }}><Link to="/dashboard/register" style={{ textDecoration: "none", "color": "#525252", }}><i className="bi bi-pencil-square" style={{ marginRight: "7px", }}></i>Add Employee</Link></Box>
            </div>

          </Grid>
        </Grid>



        <div style={{ "height": "auto", "background": "linear-gradient(to bottom right, #eb9514 0%, #fff1e0 100%)", "borderRadius": "10px" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={1} className='userImg'>
              <Item style={{ backgroundColor: "transparent", boxShadow: "none", position: 'relative' }}>
                {userData.profile_img ? <img src={userData.profile_img} className='ProfileImg' onError={(e) => {
                  e.target.src = fallbackImage;
                }} style={{ "width": "93px", "height": "93px", "border": "6px solid rgb(255, 255, 255)", "borderRadius": "60px", "objectFit": "cover", }} /> :
                  <img src='/avatar.png' className='ProfileImg' style={{ "width": "93px", "height": "93px", "border": "6px solid rgb(255, 255, 255)", "borderRadius": "60px", "objectFit": "cover", }} />}
                <Tooltip title={toTitleCase(userData.status)} arrow >
                  <Box
                    className={`${userData.status === 'active' ? 'active' : 'inactive'}`}>
                  </Box>
                </Tooltip>
              </Item>
            </Grid>
            <Grid item md={4} xs={12} sm={12}>
              <Item style={{ backgroundColor: "transparent", boxShadow: "none", textAlign: "left", marginLeft: 10 }}>
                <Typography variant="h5" gutterBottom style={{ "fontSize": "17px", marginTop: "2px", "fontFamily": "'Montserrat', sans-serif", textTransform: "capitalize", fontWeight: "bolder" }}>
                  {userData.first_name} {userData.last_name}
                </Typography>
                {(userData.address || userData.city || userData.state || userData.country || userData.zipcode) && <Typography variant="h6" gutterBottom style={{ "fontSize": "13px", "color": "#000", "fontFamily": "'Montserrat', sans-serif", "display": "inline-flex", "gap": "5px", }}>
                  <i className="bi bi-geo-alt" style={{ "fontSize": "16px", }}></i> <p>{`${userData.address || ''}${userData.address && userData.city ? ', ' : ''}${userData.city || ''}${userData.city && userData.state ? ', ' : ''}${userData.state || ''}${userData.state && userData.country ? ', ' : ''}${userData.country || ''}${userData.country && userData.zipcode ? ', ' : ''}${userData.zipcode || ''}`}
                  </p>
                </Typography>}

              </Item>
            </Grid>
            <Grid item md={4} xs={12} sm={12}>
              <Item style={{ backgroundColor: "transparent", boxShadow: "none", textAlign: "left", marginLeft: 10 }}>
                {userData.email && <Typography variant="h6" gutterBottom style={{ "fontSize": "14px", "color": "#000", "fontFamily": "'Montserrat', sans-serif", "display": "inline-flex", "gap": "5px", }}>
                  <i className="bi bi-envelope" style={{ "fontSize": "16px", }}></i><p>{`${userData.email}`}</p>
                </Typography>}<br />
                {userData.phone && <Typography variant="h6" gutterBottom style={{ "fontSize": "14px", "color": "#000", "fontFamily": "'Montserrat', sans-serif", "display": "inline-flex", "gap": "5px", }}>
                  <i className="bi bi-telephone" style={{ "fontSize": "16px", }}></i> <p> {userData.phone}</p>
                </Typography>}
              </Item>
            </Grid>
            <Grid item md={3} xs={12} sm={12} className='EditProfile'>
              <Item style={{ backgroundColor: "transparent", boxShadow: "none", marginLeft: 10 }}>
                <Box onClick={() => editProfile(userData)} style={{ "padding": "5px 10px", "float": "right", "background": "#fff", "border": "1px solid #cdcdcd", "borderRadius": "4px", "fontSize": "12px" }} component="div" sx={{ display: 'inline' }}>
                  <a style={{ textDecoration: "none", "color": "#525252", cursor: "pointer" }}><i className="bi bi-pencil-square"></i> Edit Profile</a>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </div>


        <div className="marginalog">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom style={{ "fontSize": "14px", "color": "#999", "fontFamily": "'Montserrat', sans-serif" }}>
                Shift
              </Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: "-0px", "fontSize": "14px", "fontWeight": "600", "fontFamily": "'Montserrat', sans-serif", "background": "#f7f7f7", "padding": "6px", "borderRadius": "6px" }}>
                {userData.shift_name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom style={{ "fontSize": "14px", "color": "#999", "fontFamily": "'Montserrat', sans-serif" }}>
                Location
              </Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: "-0px", "fontSize": "14px", "fontWeight": "600", "fontFamily": "'Montserrat', sans-serif", "background": "#f7f7f7", "padding": "6px", "borderRadius": "6px" }}>
                {userData.location_name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom style={{ "fontSize": "14px", "color": "#999", "fontFamily": "'Montserrat', sans-serif" }}>
                Role
              </Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: "-0px", "fontSize": "14px", "fontWeight": "600", "fontFamily": "'Montserrat', sans-serif", "background": "#f7f7f7", "padding": "6px", "borderRadius": "6px" }}>
                {userData.role_name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom style={{ "fontSize": "14px", "color": "#999", "fontFamily": "'Montserrat', sans-serif" }}>
                Date of Birth
              </Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: "-0px", "fontSize": "14px", "fontWeight": "600", "fontFamily": "'Montserrat', sans-serif", "background": "#f7f7f7", "padding": "6px", "borderRadius": "6px" }}>
                {userData.dob}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom style={{ "fontSize": "14px", "color": "#999", "fontFamily": "'Montserrat', sans-serif" }}>
                Date of Joining
              </Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: "-0px", "fontSize": "14px", "fontWeight": "600", "fontFamily": "'Montserrat', sans-serif", "background": "#f7f7f7", "padding": "6px", "borderRadius": "6px" }}>
                {userData.doj}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom style={{ "fontSize": "14px", "color": "#999", "fontFamily": "'Montserrat', sans-serif" }}>
                Bio
              </Typography>
              <Typography variant="h6" gutterBottom style={{ marginTop: "0px", "fontSize": "14px", "fontWeight": "600", "fontFamily": "'Montserrat', sans-serif", "background": "#f7f7f7", "padding": "6px", "borderRadius": "6px" }}>
                {userData.description}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className='loader'>
        {loading ? (
          <CircularProgress style={{ color: '#ED9F2A' }} />
        ) : ''}
      </div>
    </div>
  );
}