import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { ConfigApi, Helpers } from '../service';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/style.css';

const COLORS = ['#61a3db', '#0dab2f', '#e91312'];
const BARCOLORS = ['#f60', '#37403F', '#AA6C39', '#3A78AB', '#AB3A78', '#3AAB6D', '#6D3AAB', '#B83C08', '#9E7BFF', '#DCFF7A', '#FF9E7A', '#E2F516'];

export default function Dashboard() {
  const [locationData, setLocationData] = useState([]);
  const [locatiionId, setLocationId] = useState(0);
  const [attendenceData, setAttendenceData] = useState([]);
  const [shiftBarData, setShiftBarData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [shiftNames, setShiftNames] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [absentData, setAbsentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    let isLogin = Helpers.checkLogin();
    if(!isLogin)
    {
      Helpers.logout(history);
      return false;
    }
  });

  useEffect(() => {
    const userInfoString = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(userInfoString);
    getGraphDetails(userInfo.office_location);
    setLocationId(userInfo.office_location);
    getLocation();
  }, []);

  const getGraphDetails = (locId) => {
    setLocationId(locId);
    getAttendenceDetail(locId);
    getShiftGraphDetail(locId);
    getRecentData(locId);
    getAbsentData(locId);
  };

  const getRecentData = (locId) => {
    ConfigApi.getRecentData(locId).then(
      (resp) => {
        if (resp.status === 200) {
          setRecentData(resp.data.response);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getAbsentData = (locId) => {
    ConfigApi.getAbsentData(locId).then(
      (resp) => {
        if (resp.status === 200) {
          setAbsentData(resp.data.response);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  const getUniqueShifts = (data) => {
    const shifts = new Set();
    data.forEach((item) => {
      for (const key in item) {
        if (key !== 'date' && !shifts.has(key)) {
          shifts.add(key);
        }
      }
    });
    return Array.from(shifts);
  };

  const getLocation = () => {
    ConfigApi.location().then(
      (resp) => {
        if (resp.status === 200) {
          setLocationData(resp.data.response);
        } else {
          console.log("error", resp.data.error_msg);
          alert(resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };


  const getAttendenceDetail = (locId) => {
    ConfigApi.getAttendenceDetail(locId).then(
      (resp) => {
        if (resp.status === 200) {
          console.log(resp.data.response);
          setAttendenceData([
            { name: 'Leave', value: resp.data.response.leave },
            { name: 'Check in', value: resp.data.response.checkIn },
            { name: 'Late  in', value: resp.data.response.lateCheckIn },
          ]);
        } else {
          console.log("error", resp.data.error_msg);
        }
      }
    ).catch((error) => {
      Helpers.error401(error);
    });
  };

  let shiftF = [];
  const getShiftGraphDetail = (locId) => {
    setLoading(true);
    ConfigApi.getGraphDetail(locId).then(
      (resp) => {
        if (resp.status === 200) {
          console.log(resp.data.response);
          setShiftBarData(resp.data.response);
          shiftF = getUniqueShifts(resp.data.response);
          const dynamicShiftNames = Object.keys(resp.data.response[0]).filter(key => key !== 'date');
          const transformedData = resp.data.response.map(item => ({
            name: item.date,
            ...item
          }));
          setShiftData(transformedData);
          setShiftNames(dynamicShiftNames);
        } else {
          console.log("error", resp.data.error_msg);
        }
        setLoading(false);
      }
    ).catch((error) => {
      setLoading(false);
      Helpers.error401(error);
    });
  };

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${day}-${month}`;
  };

  return (
    <div style={{ "padding": "0 30px", }}>
         <Grid container spacing={2}>
  <Grid xs={7} sm={7} md={7}>
  </Grid>
  <Grid className={Helpers.cmAccess('DASHBOARDNEW_LOCATION_LIST')} xs={5} sm={5} md={5} style={{ "text-align": "initial",textAlign:"right" }} >
  <div className="positionrel">
      <label className="labelname0p" forhtml="Location">Location</label>
      <i className="bi bi-geo-alt"></i>

      <select className="formControl newcontrol" onChange={(e) => getGraphDetails(e.target.value)} value={locatiionId}>
        {
          locationData.length > 0 && locationData?.map((item, index) => (
            <option key={`location-${index}`} value={item.office_location_id}>{item.name}</option>
          ))}
      </select>
      </div>
      </Grid>

</Grid>
    
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Paper style={{ padding: 20 }} className="carddesign">
            <Typography variant="h6" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "20px", "fontWeight": "600", marginBottom: "19px", }}>Attendance Report</Typography>
            <PieChart width={400} height={300} className="piechartmain">
              <Pie
                data={attendenceData}
                cx={200}
                cy={150}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {attendenceData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper style={{ padding: 20 }} className="carddesign">
            <Typography variant="h6" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "20px", "fontWeight": "600", marginBottom: "19px", }}>Shifts</Typography>
            <BarChart width={600} height={300} data={shiftData}>
              <CartesianGrid strokeDasharray="0 0" />
              <XAxis dataKey="name" tickFormatter={formatXAxis} />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              {shiftNames.map((shiftName, index) => (
                <Bar
                  key={index}
                  dataKey={shiftName}
                  fill={BARCOLORS[index]}
                  yAxisId="left"
                />
              ))}
              
            </BarChart>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="carddesign heighttable">
            <Typography variant="h5" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "20px", "fontWeight": "600" }}>
              <i class="bi bi-shield-exclamation"></i> Current Shifts
            </Typography>
            <TableContainer component={Paper} style={{ boxShadow: "none" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "600" }}>Name</TableCell>
                    <TableCell align="right" style={{ fontWeight: "600" }}>Shift</TableCell>
                    <TableCell align="right" style={{ fontWeight: "600" }}>Time In</TableCell>
                    <TableCell align="right" style={{ fontWeight: "600" }}>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentData.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">
                        {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell align="right">{row.shift_name}</TableCell>
                      <TableCell align="right">{row.time_in}</TableCell>
                      <TableCell align="right">{row.location_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="carddesign heighttable">
            <Typography variant="h5" component="div" style={{ "fontFamily": "'Montserrat', sans-serif", "fontSize": "20px", "fontWeight": "600" }}>
              <i class="bi bi-card-text"></i> Recent Absent
            </Typography>
            <TableContainer component={Paper} style={{ boxShadow: "none" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "600" }}>Name</TableCell>
                    <TableCell align="right" style={{ fontWeight: "600" }}>Date</TableCell>
                    <TableCell align="right" style={{ fontWeight: "600" }}>Shift</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {absentData.map((row) => (
                    row?.map((data) => (
                      <TableRow
                        key={data.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">
                          {data.first_name} {data.last_name}
                        </TableCell>
                        <TableCell align="right">{data.date}</TableCell>
                        <TableCell align="right">{data.shift_name}</TableCell>
                      </TableRow>
                    ))
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
      <div className='loader'>
        {loading ? (
          <CircularProgress  style={{color:'#ED9F2A'}} />
        ) : ''}
      </div>
    </div>
  );
}