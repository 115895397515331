import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactsIcon from '@mui/icons-material/Contacts';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AssistantIcon from '@mui/icons-material/Assistant';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import BookIcon from '@mui/icons-material/Book';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import WebIcon from '@mui/icons-material/Web';
import { NavLink, useLocation} from 'react-router-dom';
import { Helpers } from '../service/index';
import '../css/style.css';
import Tooltip from '@mui/material/Tooltip';
import { ConfigApi } from '../service/index';

export default function MainListItems() {
  const location = useLocation();
  const [userinfo, setUserInfo] = React.useState({});
  const [isPages, setIsPages] = React.useState(0);

  const refreshPage = ( path) => {
    if (location.pathname === path) {
      window.location.reload();
    }
  };

  React.useEffect(() => {
    let loggedUser = Helpers.loggedUserInfo();
    if (loggedUser) {
      setUserInfo(loggedUser.role.role_key);
      callAPI("/getPagesMenu?entityId="+loggedUser.id,'getPagesMenu');
    }
  }, []);
  

  const callAPI = (url,type) => {
    if (url) {
      ConfigApi.paginationApiCall(url).then(
        (resp) => {
          if (resp.status === 200) {
			  if('getPagesMenu' === type)
			  {
            setIsPages(resp.data.data.length);
			  }
          } 
        }
      ).catch((error) => {
        Helpers.error401(error);
      });
    }
  };
  
  return (
  <React.Fragment>
    <NavLink to="/dashboard/app" className={Helpers.cmAccess('MENU_DASHBOARDNEW')} >
      <ListItemButton onClick={() => refreshPage('/dashboard/app')}>
        <Tooltip title="Dashboard" arrow>
          <ListItemIcon >
            <SpaceDashboardIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText className="menuitemss" primary="Dashboard" />
      </ListItemButton>
    </NavLink>
    <NavLink to="/dashboard/profile" className={Helpers.cmAccess('MENU_DASHBOARD')}>
      <ListItemButton onClick={() => refreshPage('/dashboard/profile')}>
        <Tooltip title="Profile" arrow>
          <ListItemIcon>
            <ContactsIcon/>
          </ListItemIcon>
        </Tooltip>
        <ListItemText className="menuitemss" primary="Profile" />
      </ListItemButton>
    </NavLink>
    <NavLink to="/dashboard/attendence" className={Helpers.cmAccess('MENU_ATTENDANCE')}>
      <ListItemButton onClick={() => refreshPage('/dashboard/attendence')}>
        <Tooltip title="Attendance" arrow>
          <ListItemIcon>
            <CalendarMonthIcon/>
          </ListItemIcon>
        </Tooltip>
        <ListItemText className="menuitemss" primary="Attendance" />
      </ListItemButton>
    </NavLink>
    {userinfo !=="END_USER" &&
    <NavLink to="/dashboard/register" className={Helpers.cmAccess('MENU_EMPLOYEE')}>
      <ListItemButton onClick={() => refreshPage('/dashboard/register')}>
        <Tooltip title="Employee" arrow>
          <ListItemIcon>
            <GroupAddIcon/>
          </ListItemIcon></Tooltip>
        <ListItemText className="menuitemss" primary="Employee" />
      </ListItemButton>
    </NavLink>}
    <NavLink to="/dashboard/shift" className={Helpers.cmAccess('MENU_SHIFT')}>
      <ListItemButton onClick={() => refreshPage('/dashboard/shift')}>
        <Tooltip title="Shift" arrow>
          <ListItemIcon>
            <AccessTimeFilledIcon/>
          </ListItemIcon></Tooltip>
        <ListItemText className="menuitemss" primary="Shift" />
      </ListItemButton>
    </NavLink>
    <NavLink to="/dashboard/Role" className={Helpers.cmAccess('MENU_ROLE')}>
      <ListItemButton onClick={() => refreshPage('/dashboard/Role')}>
        <Tooltip title="Role" arrow>
          <ListItemIcon>
            <AssistantIcon/>
          </ListItemIcon></Tooltip>
        <ListItemText className="menuitemss" primary="Role" />
      </ListItemButton>
    </NavLink>
    <NavLink to="/dashboard/location" className={Helpers.cmAccess('MENU_LOCATION')}>
      <ListItemButton onClick={() => refreshPage('/dashboard/location')}>
        <Tooltip title="Location" arrow>
          <ListItemIcon>
            <LocationOnIcon/>
          </ListItemIcon></Tooltip>
        <ListItemText className="menuitemss" primary="Location" />
      </ListItemButton>
    </NavLink>
    <NavLink to="/dashboard/report" className={Helpers.cmAccess('MENU_LOCATION')}>
      <ListItemButton onClick={() => refreshPage('/dashboard/report')}>
        <Tooltip title="Report" arrow>
          <ListItemIcon>
            <BarChartIcon/>
          </ListItemIcon>
        </Tooltip>
        <ListItemText className="menuitemss" primary="Report" />
      </ListItemButton>
    </NavLink>
    <NavLink to="/dashboard/ManualNotification" className={Helpers.cmAccess('MENU_LOCATION')}>

      <ListItemButton onClick={() => refreshPage('/dashboard/ManualNotification')}>
        <Tooltip title="Adhoc Jobs" arrow>
          <ListItemIcon>
            <BookIcon/>
          </ListItemIcon></Tooltip>
        <ListItemText className="menuitemss" primary="Adhoc Jobs" />
      </ListItemButton>
    </NavLink>
    { isPages>0 &&
    <NavLink to="/dashboard/page" className={Helpers.cmAccess('MENU_PAGES')}>
      <ListItemButton onClick={() => refreshPage('/dashboard/page')}>
        <Tooltip title="Pages" arrow>
          <ListItemIcon>
            <NoteAddIcon/>
          </ListItemIcon>
        </Tooltip>
        <ListItemText className="menuitemss" primary="Pages" />
      </ListItemButton>
    </NavLink>
}
  </React.Fragment>
  );
}