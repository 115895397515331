import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { UserApi, ConfigApi } from '../service';
import { Helpers } from '../service/index';
import { fDateOnly } from '../utils/DateUtil';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Typography, TextField, Button } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import '../css/style.css';
import { async } from 'q';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,

    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },

}));
export function Attendence() {
    const [events, setEvents] = useState([]);
    const calendarRef = useRef(null);
    const [dayDetails, setDayDetails] = useState();
    const [monthAttendence, setMonthAttendence] = useState([]);
    const [dateAttendence, setDateAttendence] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [userListData, setUserListData] = useState([]);
    const [userId, setUserId] = useState();
    const [onload, setonload] = useState();
    const [locationId, setLocationId] = useState();
    const [imageUrl, setImageUrl] = useState("");
    const [checkInTime, setCheckInTime] = useState('');
    const [checkOutTime, setCheckOutTime] = useState('');
    const [selectedDate, setSelectedDate] = useState();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    let uId = 0;
    let lId = 0;

    useEffect(() => {
        const userInfoString = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(userInfoString);
        uId = userInfo.id;
        lId = userInfo.office_location;
        getLocation();
        setLocationId(userInfo.office_location)
        getUserInfo(userInfo.id);
        getUserList(userInfo.office_location,"YES");
        setUserId(userInfo.id);
        const firstDateOfMonth = getFirstDateOfMonth(new Date());
        const lastDateOfMonth = getLastDateOfMonth(new Date());
        getAttendence(firstDateOfMonth, lastDateOfMonth,userInfo.id,userInfo.office_location);
    }, []);

    const getLocation = async () => {

        await ConfigApi.location().then(
            (resp) => {
                if (resp.status === 200) {
                    setLocationData(resp.data.response);
                    console.log("Location data", resp.data.response);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
            /* alert(error);*/
            Helpers.error401(error);
        });
    };

    const getUserList = async (id,onload) => {
        await UserApi.userlistwithLocationId(id).then(
            (resp) => {
                if (resp.status === 200) {
                    setUserListData(resp.data.response);
                    console.log("userdata", resp.data.response);
                    if (resp.data.response.length > 0 && onload=="NO") {
                        const firstDateOfMonth = getFirstDateOfMonth(new Date());
                        const lastDateOfMonth = getLastDateOfMonth(new Date());
                        setUserId(resp.data.response[0].id);
                        getUserInfo(resp.data.response[0].id);
                        getAttendence(firstDateOfMonth, lastDateOfMonth, resp.data.response[0].id, id);
                    }
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
            /* alert(error);*/
            Helpers.error401(error);
        });
    };

    const getUserInfo = async (id) => {
        await UserApi.user(id).then(
            (resp) => {
                if (resp.status === 200) {
                    setUserInfo(resp.data.response.usersdetails);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
            /* alert(error);*/
            Helpers.error401(error);
        });
    };

    const getFirstDateOfMonth = (givenDate) => {
        const year = givenDate.getFullYear();
        const month = givenDate.getMonth();
        return new Date(year, month, 1);
    };
    const getLastDateOfMonth = (givenDate) => {
        const year = givenDate.getFullYear();
        const month = givenDate.getMonth() + 1;
        return new Date(year, month, 0);
    };

    const handleDateClick = (arg) => {
        setDateAttendence([]);
        const date = arg.dateStr;
        monthAttendence?.map((attendence) => {
            if (attendence.current_date === date) {
                setDateAttendence(attendence);
            }
        });
        setDayDetails(arg.dateStr);
        console.log("Checking details ", arg.dateStr);
    };

    const handleDatesSet = (dateInfo) => {
        const start = dateInfo.start;
        const end = dateInfo.end;
        const displayedMonth = start.toLocaleString('default', { month: 'long' });
        const displayedYear = start.getFullYear();
        console.log(`Displayed Month: ${displayedMonth}, Year: ${displayedYear}`);
    };

    const handleButtonClick = (buttonInfo) => {
        if (buttonInfo.buttonType === 'next' || buttonInfo.buttonType === 'prev') {
            const calendarApi = calendarRef.current.getApi();
            const currentDate = calendarApi.getDate();
            const firstDateOfMonth = getFirstDateOfMonth(currentDate);
            const lastDateOfMonth = getLastDateOfMonth(currentDate);
            getAttendence(firstDateOfMonth, lastDateOfMonth, userId, locationId);
        } else if (buttonInfo.buttonType === 'today') {
            const currentDate = new Date();
            const firstDateOfMonth = getFirstDateOfMonth(currentDate);
            const lastDateOfMonth = getLastDateOfMonth(currentDate);
            getAttendence(firstDateOfMonth, lastDateOfMonth, userId, locationId);
        }
    };

    const timeFormatter = (timeString) => {
        const formattedTimeString = ``;
        if (timeString) {
            const dateObject = new Date(timeString);
            const hours = dateObject.getHours();
            const minutes = dateObject.getMinutes();
            const period = hours >= 12 ? "PM" : "AM";
            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0'); // Add leading zero if needed

            const formattedTimeString = `${formattedHours}:${formattedMinutes} ${period}`;
            return formattedTimeString;
        } else {
            return formattedTimeString;
        }

        console.log(formattedTimeString); // Output: "3:10:25 AM"
    };

    const getWeekendDates = (startDate, endDate, weekendDays) => {
        const weekendDates = [];
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay();
            if (weekendDays.includes(dayOfWeek)) {
                weekendDates.push(new Date(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return weekendDates;
    };


    const selectUser = (event) => {
        setDateAttendence([]);
        uId = event.target.value;
        setUserId(uId);
        getUserInfo(uId);
        const firstDateOfMonth = getFirstDateOfMonth(new Date());
        const lastDateOfMonth = getLastDateOfMonth(new Date());
        getAttendence(firstDateOfMonth, lastDateOfMonth, uId, locationId);
    };

    const selectLocation = async (event) => {
        setDateAttendence([]);
        lId = event.target.value;
        setLocationId(lId);
        await getUserList(lId,"NO");
    };


    const getAttendence = async (start, end, user_id, location_id) => {
        setLoading(true);
        try {
            const reqsBody = {
                "location_id": location_id,
                "start_date": start,
                "end_date": end,
                "user_id": user_id,
            };
            UserApi.getAttendence(reqsBody).then(
                (resp) => {
                    if (resp.status === 200) {
                        if (resp.data) {
                            if (resp.data.success) {
                                setMonthAttendence(resp.data.response);
                                const transformedEvents = resp.data.response.map((event) => ({
                                    title: event.currentDateHolidayArr.length > 0 ? event.isAbsent == true ? event.currentDateHolidayArr[0].title : 'holidaycheckin' : 'checkin',
                                    start: event.current_date,
                                    checkin: timeFormatter(event.time_in),
                                    checkout: timeFormatter(event.time_out),
                                    color: event.currentDateHolidayArr.length > 0 && event.isAbsent == true > 0 ? 'red' : 'blue'
                                }));

                                const weeks = [0, 6];

                                const weekendDays = getWeekendDates(start, end, weeks);

                                const weekends = weekendDays.map((day) => ({
                                    title: 'Weekend',
                                    start: day, // Example holiday date
                                    color: 'green',
                                }));
                                // const holidayEvents = [
                                //     {
                                //         title: 'Raksha Bandhan',
                                //         start: '2023-09-05', // Example holiday date
                                //         color: 'red',      // Customize the color
                                //     },
                                //     {
                                //         title: 'Dipawali',
                                //         start: '2023-09-15', // Example holiday date
                                //         color: 'red',      // Customize the color
                                //     },
                                // ];
                                const allEvents = [...transformedEvents, ...weekends];
                                setEvents(allEvents);
                            }
                        }
                    }
                    setLoading(false);
                });
        }
        catch (error) {
            /* console.error('Error fetching and transforming data:', error);*/
            Helpers.error401(error);
            setLoading(false);
        }
    };

    const manualAttendence = async (date, checkin, checkout, user_id, location_id, shift_id) => {
        let reqsBody = {
            "location_id": location_id,
            "time_in": checkin,
            "time_out": checkout,
            "current_date": date,
            "user_id": user_id,
            "shift_id": shift_id,
        };
        if (!checkInTime) {
            reqsBody = {
                "location_id": location_id,
                "time_out": checkout,
                "current_date": date,
                "user_id": user_id,
                "shift_id": shift_id,
            };
        } else if (!checkOutTime) {

            reqsBody = {
                "location_id": location_id,
                "time_in": checkin,
                "current_date": date,
                "user_id": user_id,
                "shift_id": shift_id,
            };
        }
            UserApi.manualAddAttendence(reqsBody).then(
            (resp) => {
                if (resp.status === 200) {
                    if (resp.data) {
                        if (resp.data.success) {
                            setError("");
                            const currentDate = new Date();
                            const firstDateOfMonth = getFirstDateOfMonth(currentDate);
                            const lastDateOfMonth = getLastDateOfMonth(currentDate);
                            getAttendence(firstDateOfMonth, lastDateOfMonth, userId, locationId);
                        }
                        else {
                            console.log("error", resp.data.error_msg);
                            toast.error(resp.data.error_msg);
                        }
                    }
                } 
            }
        ).catch((error) => {
            if (error == 'Error: Request failed with status code 400') {
                Helpers.toastError('User can not mark attendence on this date.');
            } else {
                Helpers.toastError(error);
            }
        });
    };

    const handleCheckInTimeChange = (event) => {
        setCheckInTime(event.target.value);
    };


    const handleCheckOutTimeChange = (event) => {
        setCheckOutTime(event.target.value);
    };


    const handleDateChange = (date) => {
        const formattedDate = date.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
    };

    const handleSubmit = () => {
        if ((!checkInTime || !checkOutTime) && !selectedDate) {
            setError("Please select a valid date and time.");
            return; // Prevent further execution
        }
        var timeout = `${fDateOnly(selectedDate)} ${checkOutTime}`;
        var timein = `${fDateOnly(selectedDate)} ${checkInTime}`;
        manualAttendence(selectedDate, timein, timeout, userId, locationId, userInfo.shift_id);
    };

    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split('T')[0];

    return (
        <>
            <div style={{ "padding": "0px 0px 30px 20px", }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Item style={{ boxShadow: "none", padding: "19px", "border": "none", "boxShadow": "1px 1px 21px #f3f3f3" }}>
                            <Grid container spacing={2}>

                                <Grid className={Helpers.cmAccess('ATTENDENCE_LOCATION_LIST')} item xs={12} sm={12} md={6} style={{ "text-align": "initial", }}>
                                    <p className="textyu">Location</p>
                                    <select className="formControl" onChange={selectLocation}  value={locationId}>
                                        {locationData.length > 0 && locationData?.map((item, index) => (
                                            <option value={item.office_location_id} >{item.name}</option>))
                                        }
                                    </select>
                                </Grid>

                                <Grid className={Helpers.cmAccess('ATTENDENCE_EMPLOYEE_LIST')} item xs={12} sm={12} md={6} style={{ "text-align": "initial", }}>
                                    <p className="textyu">Employee List</p>
                                    <select className="formControl" onChange={selectUser} value={userId}>
                                        {userListData.length > 0 && userListData?.map((item, index) => (
                                            <option value={item.id} >{`${item.first_name}  ${item.last_name} ( ${item.user_code} )`}</option>))
                                        }
                                    </select>
                                </Grid>

                            </Grid>
                            
                            <FullCalendar
                                ref={calendarRef}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                weekends={true}
                                events={events}
                                // dayRender={handleDayRender}
                                eventContent={renderEventContent}
                                dateClick={handleDateClick}
                                datesSet={handleDatesSet}
                                showNonCurrentDates={false}
                                fixedWeekCount={false}
                                headerToolbar={{
                                    right: 'prev,today,next',

                                }}
                                customButtons={{
                                    prev: {
                                        text: 'Prev',
                                        click: () => {
                                            const calendarApi = calendarRef.current.getApi();
                                            calendarApi.prev();
                                            handleButtonClick({ buttonType: 'prev' });
                                        },
                                    },
                                    next: {
                                        text: 'Next',
                                        click: () => {
                                            const calendarApi = calendarRef.current.getApi();
                                            calendarApi.next();
                                            handleButtonClick({ buttonType: 'next' });
                                        },
                                    },
                                    today: {
                                        text: 'Today',
                                        click: () => {
                                            const calendarApi = calendarRef.current.getApi();
                                            calendarApi.today();
                                            handleButtonClick({ buttonType: 'today' });
                                        },
                                    },
                                }}
                            />
                            <div className='loader'>
                            {loading ? (
                            <CircularProgress  style={{color:'#ED9F2A'}}/>
                            ) : ''}
                        </div>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} style={{ fontFamily: "Montserrat, sans-serif !important", }}>
                        <div className="profilebg"></div>
                        <Item style={{ padding: 17, marginTop: 0, "border": "none", "boxShadow": "1px 1px 21px #f3f3f3" }}>
                            <div style={{ "position": "sticky", "zIndex": "9", "marginTop": "12px" }}>

                                {/* <img src={imageUrl} style={{ "width": "80px", "height": "80px", "borderRadius": "70px", "border": "5px solid #fff", "boxShadow": "1px 1px 3px #d7d7d7" }} /> */}

                                <Typography style={{
                                    "textAlign": "center", "fontSize": "20px", "fontFamily": "Montserrat, sans-serif", "fontWeight": "600", "color": "#fff", "paddingBottom": "19px",
                                    "marginTop": "-10px"
                                }}>{userInfo.first_name} {userInfo.last_name}</Typography>

                                <div style={{ textAlign: "left" }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Box style={{
                                            "margin": '0px 0 10px 23px', "width": "100%", "background": "#fff",
                                            "border-top-left-radius": "10px",
                                            "border-top-right-radius": "10px",
                                        }}>
                                            <Grid item xs={12} sm={6} md={12} style={{ marginTop: 15, }}>
                                                <Item className="shpwdata">Date: <div className="answer">{dateAttendence.current_date}</div> </Item>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={12} style={{}}>
                                                <Item className="shpwdata"  > Total Minutes: <div className="answer">{dateAttendence.total_minutes}</div></Item>
                                            </Grid>


                                            <Grid item xs={12} sm={8} md={12} style={{}}>
                                                <Item className="shpwdata"  > Check-in:<div className="answer"> {timeFormatter(dateAttendence.time_in)}</div></Item>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={12} style={{}}>
                                                <Item className="shpwdata"  > Check-out:<div className="answer"> {timeFormatter(dateAttendence.time_out)}</div></Item>
                                            </Grid>


                                            <Grid item xs={12} sm={6} md={12} style={{}}>
                                                <Item className="shpwdata" > Shift:<div className="answer">{dateAttendence.shift_name}</div></Item>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={12} style={{ marginBottom: 15, }}>
                                                <Item className="shpwdata" > Location:<div className="answer">{dateAttendence.name}</div> </Item>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <div className={Helpers.cmAccess('ATTENDENCE_MANUAL')}>
                                    <TextField
                                        className="spacing"
                                        label="Check-in Time"
                                        type="time"
                                        value={checkInTime}
                                        onChange={handleCheckInTimeChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5-minute intervals
                                        }}
                                        fullWidth
                                    />

                                    <TextField
                                        className="spacing"

                                        label="Check-out Time"
                                        type="time"
                                        value={checkOutTime}
                                        onChange={handleCheckOutTimeChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5-minute intervals
                                        }}
                                        fullWidth
                                    />

                                    {/* Add date picker */}
                                    <TextField
                                        className="spacing"

                                        label="Select Date"
                                        type="date"
                                        value={fDateOnly(selectedDate)} // Format the selected date if needed
                                        onChange={(e) => handleDateChange(new Date(e.target.value))}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        inputProps={{
                                            max: currentDateString, // Set the maximum date
                                          }}
                                    />

                                    {error && (
                                        <div style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
                                            {error}
                                        </div>
                                    )}
                                    <div className={Helpers.cmAccess('ATTENDENCE_MANUAL')}>
                                        {/* Add a submit button */}
                                        <Button
                                            className="btnoo"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                            fullWidth
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

function renderEventContent(eventInfo) {
    const isWeekend = eventInfo.event.backgroundColor === 'green';
    // const isHoliday = eventInfo.event.backgroundColor === 'red';
    // const isWeekday = eventInfo.event.backgroundColor === 'blue';

    const isHolidaycheckIn = eventInfo.event.title === 'holidaycheckin';
    const isHoliday = eventInfo.event.title !== 'holidaycheckin' && eventInfo.event.title !== 'checkin';
    const isWeekday = eventInfo.event.title === 'checkin';

    return (
        <>
            {isHoliday &&
                <div style={{ backgroundColor: eventInfo.event.backgroundColor, padding: 2 }}>
                    <span style={{ color: 'white' }}>{eventInfo.event.title}</span>
                </div>
            }
            {isWeekday &&
                <div>
                    <Tooltip title={`Check-in: ${eventInfo.event.extendedProps.checkin}`}>
                    <span>Check-in: {eventInfo.event.extendedProps.checkin}</span>
                    </Tooltip>
                    <br />
                    <Tooltip title={`Check-out: ${eventInfo.event.extendedProps.checkout}`}>
                    <span>Check-out: {eventInfo.event.extendedProps.checkout}</span>
                    </Tooltip>
                </div>
            }
            {/* {isWeekend &&
                <div style={{ backgroundColor: 'green', padding: 2 }}>
                    <span style={{ color: 'white' }}>{eventInfo.event.title}</span>
                </div>
            } */}
            {isHolidaycheckIn &&
                <div style={{ backgroundColor: eventInfo.event.backgroundColor, padding: 2 }}>
                    {/* <span style={{ color: 'white' }}>{eventInfo.event.title}</span> */}
                    <Tooltip title={`Check-in: ${eventInfo.event.extendedProps.checkin}`}>
                    <span>Check-in: {eventInfo.event.extendedProps.checkin}</span>
                    </Tooltip>
                    <br />
                    <Tooltip title={`Check-out: ${eventInfo.event.extendedProps.checkout}`}>
                    <span>Check-out: {eventInfo.event.extendedProps.checkout}</span>
                    </Tooltip>
                </div>
            }
        </>
    );
}
