export function fDateOnly(dateString) {
    const parsedDate = new Date(dateString);
    const formattedDate = parsedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    
    console.log(formattedDate); // Output: "08/31/2023" (MM/DD/YYYY format)
    const parts = formattedDate.split('/');
    const yyyy = parts[2];
    const mm = parts[0];
    const dd = parts[1];
    const formattedDateISO = `${yyyy}-${mm}-${dd}`;
    return formattedDateISO; 
}
