import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { UserApi, ConfigApi } from '../service';
import { Helpers } from '../service/index';
import { fDateOnly } from '../utils/DateUtil';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Typography, TextField, Button } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import '../css/style.css';
import { async } from 'q';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,

    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },

}));
export function ManualNotification() {
    const [events, setEvents] = useState([]);
    const calendarRef = useRef(null);
    const [dayDetails, setDayDetails] = useState();
    const [monthAttendence, setMonthAttendence] = useState([]);
    const [dateAttendence, setDateAttendence] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [userListData, setUserListData] = useState([]);
    const [userId, setUserId] = useState();
    const [locationId, setLocationId] = useState();
    const [imageUrl, setImageUrl] = useState("");
    const [checkInTime, setCheckInTime] = useState('');
    const [checkOutTime, setCheckOutTime] = useState('');
    const [selectedDate, setSelectedDate] = useState();
    const [error, setError] = useState("");
    let uId = 0;
    let lId = 0;

    useEffect(() => {
        const userInfoString = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(userInfoString);
        uId = userInfo.id;
        lId = userInfo.office_location;
        setLocationId(userInfo.office_location)
        setUserId(userInfo.id);
        getLocation();
        getUserList(userInfo.office_location,"YES");
    }, []);

    const getLocation = async () => {
  
        await ConfigApi.location().then(
            (resp) => {
                if (resp.status === 200) {
                    setLocationData(resp.data.response);
                    console.log("Location data", resp.data.response);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
            /* alert(error);*/
            Helpers.error401(error);
        });
      };
      
      const getUserList = async (id,onload) => {
        await UserApi.userlistwithLocationId(id).then(
            (resp) => {
                if (resp.status === 200) {
                    setUserListData(resp.data.response);
                    console.log("userdata", resp.data.response);
                    if (resp.data.response.length > 0 && onload=="NO") {
                        setUserId(resp.data.response[0].id);
                    }
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
            /* alert(error);*/
            Helpers.error401(error);
        });
      };

    const selectUser = (event) => {
        uId = event.target.value;
        setUserId(event.target.value);
    };

    const selectLocation = async (event) => {
        lId = event.target.value;
        setLocationId(event.target.value);
        await getUserList(event.target.value,"NO");
    };

    const handleSubmit = () => {
        if (!userId && !locationId) {
            setError("Please select a loction and user.");
            return; // Prevent further execution
        }
        sendNoti(userId);
    };

    const sendNoti = async (id) => {
        await ConfigApi.manualNotification(id).then(
            (resp) => {
                if (resp.status === 200) {
                    Helpers.toastSuccess('Notification sent');
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
            if (error == 'Error: Request failed with status code 400') {
                Helpers.toastError('User is absent');
            } else {
                Helpers.toastError(error);
            }
            //    alert(error);
            //    Helpers.error(error);
        });
    };

    return (
        <>
            <div style={{ "padding": "0px 0px 30px 20px", }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Item style={{ boxShadow: "none", padding: "19px", "border": "none", "boxShadow": "1px 1px 21px #f3f3f3" }}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} md={4} sm={6} style={{ display:"inline-grid" }}>
                                    <p className="textyu">Location</p>
                                    <select className="formControl" onChange={selectLocation} value={locationId}>
                                        {locationData.length > 0 && locationData?.map((item, index) => (
                                            <option value={item.office_location_id} >{item.name}</option>))
                                        }
                                    </select>
                                </Grid>

                                <Grid item xs={12} md={4} sm={6} style={{ display:"inline-grid" }}>
                                    <p className="textyu">Employee List</p>
                                    <select className="formControl" onChange={selectUser} value={userId}>
                                        {userListData.length > 0 && userListData?.map((item, index) => (
                                            <option value={item.id} >{`${item.first_name}  ${item.last_name} ( ${item.user_code} )`}</option>))
                                        }
                                    </select>
                                </Grid>

                                <Grid className={Helpers.cmAccess('MANUALNOTIFICATION_SUBMIT')} item xs={12} md={4} sm={6}  style={{ marginTop: 28, }}>
                                    <p className="textyu">    </p>
                                    <Button
                                        className="btnoo"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        fullWidth
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                {error && (
                                    <div style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
                                        {error}
                                    </div>
                                )}
                            </Grid>
                            {/*<Grid container spacing={2}>
                                <Grid item xs={12} md={4} sm={6}>
                                <FormControl fullWidth>
                                        <InputLabel className="bgwhite">Employee Name</InputLabel>
                                        <Select
                                            className="textleft"
                                            label="Age"
                                          
                                        >
                                            <MenuItem value={10}>Ravi Kumawat</MenuItem>
                                            <MenuItem value={20}>Sourabh Sharma</MenuItem>
                                            <MenuItem value={30}>Arvind Sharma</MenuItem>
                                            <MenuItem value={40}>Depesh jangid</MenuItem>
                                            <MenuItem value={50}>Satendar sharma</MenuItem>
                                            <MenuItem value={60}>Deepak Sain</MenuItem>
                                            <MenuItem value={70}>Raviraj singh</MenuItem>
                                        </Select>
                                        </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel className="bgwhite">Location</InputLabel>
                                    <Select
                                        label="Age"
                                        className="textleft"

                                    >
                                        <MenuItem value={10}>Vaishali Nagar</MenuItem>
                                        <MenuItem value={20}>Mansarover</MenuItem>
                                        <MenuItem value={30}>Jaipur</MenuItem>
                                    </Select>
                                    </FormControl>
                                </Grid>
                            
                            </Grid> */}
                        </Item>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

