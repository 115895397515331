import smartAttendence from "../axios/axios";

let institution_id = "";
if(localStorage.getItem('token'))
{
	institution_id = JSON.parse(localStorage.getItem('institution_id'));
}

const config = {
    headers: {
            'content-type': 'multipart/form-data'
                }
        }
        
class UserService {
    
    user(data) {
        return Promise.resolve(smartAttendence.get(`getProfile/${data}?institution_id=${institution_id}&channel=browser`));
    }
    userlist() {
        return Promise.resolve(smartAttendence.get(`getUserList?institution_id=${institution_id}&channel=browser`));
    }
    userlistwithLocationId(data) {
        return Promise.resolve(smartAttendence.get(`getUserList/${data}?institution_id=${institution_id}&channel=browser`));
    }
    getAttendence(data) {
        return Promise.resolve(smartAttendence.post(`getAttendence?institution_id=${institution_id}&channel=browser`, data));
    }
    manualAddAttendence(data) {
        return Promise.resolve(smartAttendence.post(`manualAddAttendence?institution_id=${institution_id}&channel=browser`, data));
    }
    register(data) {
        return Promise.resolve(smartAttendence.post(`create-user?institution_id=${institution_id}&channel=browser`, data, config));
    }
    updateEmployee(data,id) {
        return Promise.resolve(smartAttendence.post(`create-user/${id}?institution_id=${institution_id}&channel=browser`, data, config));
    }
}

export default UserService;