import { Navigate, useRoutes } from 'react-router-dom';

import Login from './pages/Login';
import SearchCompany from './pages/SearchCompany';
import Register from './pages/Register';
import Forgotpass from './pages/Forgotpass';
import Personalinfo from './pages/Personalinfo';
import Previousservice from './pages/Previousservice';
import DashboardApp from './pages/DashboardApp';
import DashboardLayout from './Layouts/DashboardLayout';
import Config from './pages/Config';
import Shift from './pages/Shift';
import Addshift from './pages/Addshift';
import Role from './pages/Role';
import AddRole from './pages/AddRole';
import Addlocation from './pages/Addlocation';
import Location from './pages/Location';
import { Attendence } from './pages/Attendence';
import {ManualNotification} from './pages/ManualNotification';
import Access from './pages/Access';
import Report from './pages/Report';
import Mainpage from './pages/MainPages';
import PageDetails from './pages/PageDetails';
import MainPageResponses from './pages/MainPageResponses';
import Dashboard from './pages/Dashboard';
export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout/>,
      children: [
        { path: '', element: <Navigate to="/login" replace /> },
       
        { path: 'app', element: <Dashboard/> },
        { path: 'profile', element: <DashboardApp/> },
        { path: 'register', element: <Register/> },
        { path: 'config', element: <Config/> },
        { path: 'shift', element: <Shift/> },
        { path: 'shift/addshift', element: <Addshift/> },
        { path: 'shift/addshift/:id', element: <Addshift/> },
        { path: 'role', element: <Role/> },
        { path: 'role/addRole', element: <AddRole/> },
        { path: 'role/addRole/:id', element: <AddRole/> },
        { path: 'location/addlocation', element: <Addlocation/> },
        { path: 'location/addlocation/:id', element: <Addlocation/> },
        { path: 'location', element: <Location/> },
        { path: 'attendence', element: <Attendence/> },
        { path: 'ManualNotification', element: <ManualNotification/> },
        { path: 'report', element: <Report/> },
        { path: 'role/access/:id', element: <Access/> },
        { path: 'page', element: <Mainpage/> },
		    { path: 'page/:id', element: <PageDetails/> },
        { path: 'page/:id/:pagedetailsid', element: <PageDetails/> },
		    { path: 'page/mainPageResponses/:id', element: <MainPageResponses/> },
        
      ]
    },
    {
      path: '/',
   //   element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <Login /> },
        { path: 'login', element: <Login /> },
        { path: 'forgotpass', element: <Forgotpass /> },
        { path: 'personalinfo', element: <Personalinfo /> },
        { path: 'previousservice', element: <Previousservice /> },
        { path: 'searchCompany', element: <SearchCompany  /> },

      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
