import React, { useState, useEffect } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import { ConfigApi, Helpers, UserApi } from '../service/index';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import '../css/style.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

// TODO remove, this demo shouldn't need to reset the theme.
export default function PageDetails() {
  const [loading, setLoading] = useState(false);
  const [getPagesMenu, setPagesMenu] = useState([]);
  const [getQuestionMapping, setQuestionMapping] = useState([]);
  const [value, setValue] = useState(0);
  const [pagedetailsidValue, setPagedetailsidValue] = useState(0);
  
  const ControlTypes = Helpers.getControlType();
  const navigate = useNavigate();
  let { id } = useParams();
  let { pagedetailsid } = useParams();
  

  

    const switchTab = (pageConfigId) => {
      
    getPagesMenu?.forEach((menuPage, index) => {
     
      //setValue(pageConfigId);
      if(menuPage.pageConfigId == pageConfigId)
      {
          if(menuPage?.pageConfigDetail?.length > 0)
          {
            let loggedUser = Helpers.loggedUserInfo();
            callAPI("/getQuestionMapping?pageConfigDetailId="+menuPage.pageConfigDetail[0].pageConfigDetailId+"&entityId="+loggedUser.id,'getQuestionMapping')
            navigate('/dashboard/page/'+menuPage.pageConfigDetail[0].pageConfigDetailId, { replace: true})
          }
      }

    })
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event, pageQuestionsMappingId,pageQuestionConfigId, newValue) => {
    const data = [...getQuestionMapping];
    //console.log(data);
      data?.forEach((control, index) => {
        
        if(control.pageQuestionsMappingId == pageQuestionsMappingId)
        {
          let isMandatory = control.isMandatory;
          control?.pageQuestionConfigs?.forEach((pageQuestionConfigs, index) => {
            let controlName = pageQuestionConfigs.pageControlTypes.controlName;
            if(controlName == 'TextField' || controlName == 'TextArea' || controlName == 'Date')
            {
              pageQuestionConfigs.responseDetails[0].responseNote = newValue;
            }
            if(controlName == 'DropDown' || controlName == 'RadioButton')
            {
              pageQuestionConfigs.responseDetails[0].pageQuestionConfigRespDetailId = newValue;
              pageQuestionConfigs.responseDetails[0].responseNote = "";
            }
            if(controlName == 'Range')
            {
              
              pageQuestionConfigs.responseDetails[0].pageQuestionConfigRespDetailId = pageQuestionConfigs.pageQuestionConfigRespDetail[0].pageQuestionConfigRespDetailId;
              pageQuestionConfigs.responseDetails[0].responseNote = newValue;
            }
            else  if(controlName == 'CheckBox') {
                  let checked = true;
                  pageQuestionConfigs?.responseDetails.forEach((responseDetails, index) => {
                  if(responseDetails.pageQuestionConfigRespDetailId == event.target.value){
                    responseDetails.checked = event.target.checked;
                  }
                  if(responseDetails.checked)
                  {
                    checked = false;
                  }
                })

                if(isMandatory)
                {
                  pageQuestionConfigs?.responseDetails.forEach((responseDetails, index) => {
                      responseDetails.isMandatory = checked;
                  })
                }
            }
            else  if(controlName == 'MultipleTextbox') {
              pageQuestionConfigs?.responseDetails.forEach((responseDetails, index) => {
                if(responseDetails.pageQuestionConfigRespDetailId == event.target.name){
                  responseDetails.responseNote = newValue;
                }
              })
            }
            else{
             // pageQuestionConfigs?.responseDetails =[];
             
            }
          })
        }
      })
     setQuestionMapping(data);
  }
 

  const handleSubmit = (e) => {
    e.preventDefault();
    //debugger
    if(pagedetailsidValue > 0 && !pagedetailsid)
    {
      pagedetailsid = pagedetailsidValue;
    }
    // You can perform form validation and submit the data here
    const loggedUser = Helpers.loggedUserInfo();
    if(pagedetailsid){
      postApiCall('/submitDetail/'+pagedetailsid+'?editAdd=true',{entityId:loggedUser.id,pageConfigDetailId:id,questionMappingInfo:JSON.stringify(getQuestionMapping)},'submitDetail');
    }
    else{
      postApiCall('/submitDetail?isAdd=true',{entityId:loggedUser.id,pageConfigDetailId:id,questionMappingInfo:JSON.stringify(getQuestionMapping)},'submitDetail');
    }
   
   
  };

 



  const callAPI = (url,type) => {
    setLoading(true);
    if (url) {
      ConfigApi.paginationApiCall(url).then(
        (resp) => {
          if (resp.status === 200) {
            if('getPagesMenu' === type)
            {
              setPagesMenu(resp.data.data);
            }
            else if('getQuestionMapping' === type)
            {
              //debugger;
              createResponeData(resp.data.data);
              if(resp.data.pageConfigDetail.pageDetailId)
              {
                pagedetailsid = resp.data.pageConfigDetail.pageDetailId;
                setPagedetailsidValue(pagedetailsid?pagedetailsid:0);
              }
                            
            } 
            setLoading(false);
          } else {
            console.log("error", resp.data.error_msg);
          }
          setLoading(false);
        }
      ).catch((error) => {
        setLoading(false);
        Helpers.error401(error);
      });
    }
  };

  const postApiCall = (url,data,type) => {
    setLoading(true);
      if (url) {
        ConfigApi.postApiCall(url,data).then(
          (resp) => {
            if (resp.status === 200) {
              Helpers.toastSuccess('success');
              let loggedUser = Helpers.loggedUserInfo();
              callAPI("/getQuestionMapping?pageConfigDetailId="+id+"&entityId="+loggedUser.id,'getQuestionMapping')
            } else {
              console.log("error", resp.data.error_msg);
            }
            setLoading(false);
          }
        ).catch((error) => {
          setLoading(false);
          Helpers.error401(error);
        });
      }
    };

  const createResponeData = (data)=>{
    
    data.forEach((pageQuestionConfg, index) => {
      pageQuestionConfg?.pageQuestionConfigs?.forEach((control, index) => {
        let controlName = control.pageControlTypes.controlName;
        if(controlName == 'TextField' || controlName == 'TextArea' || controlName == 'Date') {
            if(!control?.responseDetails)
            {
              control.responseDetails = [{
                pageConfigId:pageQuestionConfg.pageConfigId,
                pageQuestionConfigId:control.pageQuestionConfigId,
                responseNote:'',
                isMandatory : pageQuestionConfg.isMandatory?true:false
              }];
            }
           
        }
        else  if(controlName == 'DropDown' || controlName == 'Range'|| controlName == 'RadioButton') {
          if(!control?.responseDetails)
          {
            control.responseDetails = [{
                pageConfigId:pageQuestionConfg.pageConfigId,
                pageQuestionConfigId:control.pageQuestionConfigId,
                pageQuestionConfigRespDetailId:'',
                responseNote:0,
                isMandatory : pageQuestionConfg.isMandatory?true:false
            }];
          }
        }
        else  if(controlName == 'CheckBox') {
          if(!control?.responseDetails)
          {
            control.responseDetails = control.pageQuestionConfigRespDetail;
            control?.responseDetails.forEach((responseDetails, index) => {
              responseDetails.checked = false;
              responseDetails.isMandatory = pageQuestionConfg.isMandatory?true:false
            })
          }
          else{
            let responseDetailsArray = [];
            control?.pageQuestionConfigRespDetail.forEach((respDetail, index) => {
              respDetail.checked = false;
              respDetail.isMandatory = false;
              let respDetailArray = respDetail;

              let isMandatory = pageQuestionConfg.isMandatory?true:false;           
                control?.responseDetails.forEach((responseDetails, index) => {
                if(respDetail.pageQuestionConfigRespDetailId == responseDetails.pageQuestionConfigRespDetailId)
                {
                  respDetail.checked = true;
                  respDetail.pageResponseDetailId = responseDetails.pageResponseDetailId;
                  respDetailArray = respDetail;
                }
              });
              responseDetailsArray.push(respDetailArray);
            });
            control.responseDetails = responseDetailsArray;
            
          }
        }
        else  if(controlName == 'MultipleTextbox') {
          if(!control?.responseDetails)
          {
            control.responseDetails = control.pageQuestionConfigRespDetail;
            control?.responseDetails.forEach((responseDetails, index) => {
              responseDetails.isMandatory = pageQuestionConfg.isMandatory?true:false;
              responseDetails.responseNote = '';
            })
          }
          else{
            let responseDetailsArray = [];
            control?.pageQuestionConfigRespDetail.forEach((respDetail, index) => {
              
              respDetail.responseNote = "";
              let isMandatory = pageQuestionConfg.isMandatory?true:false;  
              let respDetailArray = respDetail;
              respDetail.isMandatory = isMandatory;         
                control?.responseDetails.forEach((responseDetails, index) => {
                if(respDetail.pageQuestionConfigRespDetailId == responseDetails.pageQuestionConfigRespDetailId)
                {
                  respDetail.responseNote = responseDetails.responseNote;
                  respDetail.pageResponseDetailId = responseDetails.pageResponseDetailId;
                  respDetail.isMandatory = isMandatory;
                  respDetailArray = respDetail;
                }
              });
              responseDetailsArray.push(respDetailArray);
            });
            control.responseDetails = responseDetailsArray;
          }
        }
        
      });

    });
     setQuestionMapping(data);
  }
  useEffect(() => {
   
    let loggedUser = Helpers.loggedUserInfo();
    callAPI("/getPagesMenu?entityId="+loggedUser.id,'getPagesMenu');

    if(pagedetailsid){
      callAPI("/getQuestionMapping/"+pagedetailsid+"?pageConfigDetailId="+id,'getQuestionMapping')
    }
    else{
      callAPI("/getQuestionMapping?pageConfigDetailId="+id+"&entityId="+loggedUser.id,'getQuestionMapping')
    }

   
	  
  }, []);

  useEffect(() => {

    getPagesMenu?.forEach((menuPage, index) => {
      if(menuPage.pageConfigId == id){
        setValue(index);
      }
    })

  }, [getPagesMenu]);
  
  function DynamicForm(pageQuestionConfigsMapp,control) {
    //console.log(pageQuestionConfigsMapp);
    let pageQuestionsMappingId = pageQuestionConfigsMapp.pageQuestionsMappingId;
    let isMandatory = pageQuestionConfigsMapp.isMandatory;
    switch (control.pageControlTypes.controlName) {
      case ControlTypes.TextField:
        return (
          <div key={control.pageQuestion}>
            <label className="labelmenu">{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
            <input className="formmenu" type="text" required={isMandatory?true:false} value={control.responseDetails[0].responseNote}  onChange={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)} />
          </div>
        );
        case ControlTypes.Date:
        return (
          <div key={control.pageQuestion} className="margintop">
            <label className="labelmenu">{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
            <input  className="formmenu" type="date" required={isMandatory?true:false}  value={control.responseDetails[0].responseNote}  onChange={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)} />
          </div>
        );
        case ControlTypes.TextArea:
          return (
            <div key={control.pageQuestion}>
              <label className="labelmenu">{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
              <textArea  className="textareamenu" required={isMandatory?true:false}   onChange={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)}>{control.responseDetails[0].responseNote}</textArea>
            </div>
          );
  
          case ControlTypes.DropDown:
            return (
              <div key={control.pageQuestion}>
                <label className="labelmenu">{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
                <select  className="formmenu" required={isMandatory?true:false}  onChange={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)}><option value="" >select</option>
                {control?.pageQuestionConfigRespDetail?.map((configRespDetail, index) => (
                  <option selected={configRespDetail?.pageQuestionConfigRespDetailId == control?.responseDetails[0]?.pageQuestionConfigRespDetailId?true:false} value={configRespDetail.pageQuestionConfigRespDetailId}>{configRespDetail.responseConfig}</option>
                   ))}
                </select>
              </div>
            );

            case ControlTypes.Range:
            return (
              <div className="relativeRange" key={control.pageQuestion}>
                <label className="labelmenu">{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
               
                {control?.pageQuestionConfigRespDetail?.map((configRespDetail, index) => (
                  <>
                  <p className="maxone">Min- {configRespDetail.responseConfig.split(",")[0]}</p><input  className="formmenu" type="range" required={isMandatory?true:false}  value={control.responseDetails[0].responseNote} onChange={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)} min={configRespDetail.responseConfig.split(",")[0]} max={configRespDetail.responseConfig.split(",")[1]}/><p className="maxtwo">Max-{configRespDetail.responseConfig.split(",")[1]}</p> {control.responseDetails[0].responseNote}</>
                   ))}
              
              </div>
            );

            case ControlTypes.RadioButton:
              return (
                <div key={control.pageQuestion}>
                  <label className="labelmenu">{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
                 <div className="radiocont">
                  {control?.pageQuestionConfigRespDetail?.map((configRespDetail, index) => (
                    <label>
                    <input  required={isMandatory?true:false} checked={configRespDetail?.pageQuestionConfigRespDetailId == control.responseDetails[0].pageQuestionConfigRespDetailId?true:false}  type="radio" onClick={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)} name={`radio-${control.pageQuestionConfigId}`} value={configRespDetail.pageQuestionConfigRespDetailId} /> {configRespDetail.responseConfig}</label>
                     ))}
                </div>
                

                </div>
              );
              
              case ControlTypes.RadioButton_Numerical:
              return (
                <div key={control.pageQuestion}>
                  <label className="labelmenu">{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
                 
                  {control?.pageQuestionConfigRespDetail?.map((configRespDetail, index) => (
                     <label>
                    <input type="radio" required={isMandatory?true:false}  onClick={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)} name={`radio-${control.pageQuestionConfigId}`} value={configRespDetail.pageQuestionConfigRespDetailId} /> {configRespDetail.responseConfig}</label>
                     ))}
                
                </div>
              );
              case ControlTypes.CheckBox:
                return (
                  <div key={control.pageQuestion}>
                    <label className="labelmenu">{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
                 <div className="radiocont">
                   
                    {control?.responseDetails?.map((configRespDetail, index) => (
                       <label>
                      <input type="checkbox" required={configRespDetail.isMandatory?true:false}  onChange={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)} name={`checkbox-${control.pageQuestionConfigId}`} checked={configRespDetail.checked} value={configRespDetail.pageQuestionConfigRespDetailId} /> {configRespDetail.responseConfig}
                      </label>
                       ))}
                  </div>
                  
                  </div>
                );
            case ControlTypes.MultipleTextbox:
              return (
                <div key={control.pageQuestion}>
                  <label>{control.pageQuestion} {isMandatory?<span className="redreq">*</span>:''}</label>
                
                  {control?.pageQuestionConfigRespDetail?.map((configRespDetail, index) => (
                   <>
                   <label className="labelmenu"> {configRespDetail.responseConfig}</label>
                    <input  className="formmenu" type="text" required={configRespDetail.isMandatory?true:false}  onChange={(e) => handleChange(e,pageQuestionsMappingId,control.pageQuestionConfigId,e.target.value)} name={configRespDetail.pageQuestionConfigRespDetailId} value={configRespDetail.responseNote} /> </>
                    ))}
                
                </div>
              );

             
              case ControlTypes.Information:
              return (
                <div key={control.pageQuestion}>
                  <label>{control.pageQuestion}:</label>
                 
                  {control?.pageQuestionConfigRespDetail?.map((configRespDetail, index) => (
                    <>
                   <label> {configRespDetail.responseConfig}</label>
                   </>
                     ))}
                
                </div>
              );

      default:
        return "";
    }

}


  return (
    <div style={{ background: '#fff', "margin": "0 30px", "borderRadius": "10px" }}>
    <div className="bgwhite2">
      <Tabs
        value={value}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        left
      >
	    {getPagesMenu?.map((row, index) => (
			   <Tab label={row.pageName} onClick={(event) => switchTab(row.pageConfigId)} />
      ))}
       
      </Tabs>
      <p className={Helpers.cmAccess('DAYNMIC_PAGE_RESPONSE_LIST')}><NavLink className="addButton"  key={`pageConfigadd${id}`} to={`/dashboard/page/mainPageResponses/${id}`} >List</NavLink></p>
		 <form onSubmit={handleSubmit} className="my-form">
       <Grid container spacing={2}>

       {getQuestionMapping?.map((pageQuestionConfg, index) => (
      <Grid item xs={6}>
        <div>
          {pageQuestionConfg?.pageQuestionConfigs.map((control) => DynamicForm(pageQuestionConfg,control))}
        </div>
      </Grid>
        
     ))}
      </Grid>

     <input type="submit" className="btncolor" />
     
      </form>
      <div className='loader'>
          {loading ? (
            <CircularProgress style={{color:'#ED9F2A'}}/>
          ) : ''}
        </div>
        </div>
    </div>
  );
}

