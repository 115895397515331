import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import {  createTheme } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Box, TextField, Button, FormControl, 
    InputLabel, ToggleButtonGroup, ToggleButton, Select, MenuItem, FilledInput, IconButton} from '@mui/material';

import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { fDateOnly } from '../utils/DateUtil';
import { ConfigApi, UserApi, Helpers } from '../service/index';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import '../css/style.css';

const theme = createTheme();

export default function Register() {  
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const [shiftData, setShiftData] = useState([]);
    const [status, setStatus] = useState("active");
    const [locationId, setLocationId] = useState();
    const [locationData, setLocationData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [govtIds, setGovtIds] = useState([]);
    const [userData, setUserData] = useState([]);
    const [userExperienceData, setUserExperienceData] = useState([]);
    const [govtIdImage, setGovtIdImage] = useState();
    const [blacklist, setBlacklist] = useState("0");
    const [employeeImage, setEmployeeImage] = useState();
    const [openAccordion, setOpenAccordion] = useState('personalInfo');
    const [dob, setDob] = useState(dayjs(null));
    const [doj, setDoj] = useState(dayjs(null));
    const [registerData, setRegisterData] = useState();
    const [existUser, setExistUser] = useState(false);
    const [isEndUser, setIsEndUser] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
         //   getShift();
            getLocation();
            getRole();
           // getUserDetail();
            getGovtIds();

        if (state) {
            console.log("registered Data",state)
            setRegisterData(state); 
            setExistUser(true);
            getUserDetails(state);
            if(JSON.parse(localStorage.getItem('userInfo')).role.role_key === "END_USER"){
                setIsEndUser(true);
            }
        } 
        
            
    }, []);

    const getUserDetails = (userId) => {
        UserApi.user(userId).then(
          (resp) => {
            if (resp.status === 200) {
              const data = resp.data.response.usersdetails;
              const experience = resp.data.response.experiencedetails

              setBlacklist(data.blacklist);
              setDob(data.dob);
              setDoj(data.doj);
              setStatus(data.status);
              formik.setFieldValue('firstName', data.first_name);
              formik.setFieldValue('lastName', data.last_name);
              formik.setFieldValue('email', data.email);
              formik.setFieldValue('password', '********');
              formik.setFieldValue('mobileNum', data.phone);
              formik.setFieldValue('description', data.description);
      
              formik.setFieldValue('address1', data.address);
              formik.setFieldValue('city', data.city);
              formik.setFieldValue('state', data.state);
              formik.setFieldValue('zipcode', data.zipcode);
              formik.setFieldValue('country', data.country);
      
              formik.setFieldValue('officeLocation', data.office_location);
              getShiftByLocation(data.office_location);
              formik.setFieldValue('shiftId', data.shift_id);
              formik.setFieldValue('role', data.role_id);
              formik.setFieldValue('govt_id_type', data.govtid_type_id);

              if(experience.length>0){
                    const finalExp = [];
                    experience?.map((exp) => {
                        const expr = 
                            {
                                employer_name: exp.employer_name, 
                                previous_role: exp.previous_role, 
                                start_date: exp.start_date, 
                                end_date: exp.end_date, 
                                reason_of_leave: exp.reason_of_leave
                            }
                            finalExp.push(expr);
                    });
                    setFormBlocks(finalExp);
              }
            }else {
              console.log("error",resp.data.error_msg);
              alert(resp.data.error_msg);
            }}
        ) .catch((error) => {
          /*alert(error);*/
          Helpers.error401(error);
        });
      };

    const handleChangeStatus = (event, newAlignment) => {
        setStatus(newAlignment);
    };
    const handleChange = (event, newAlignment) => {
        setBlacklist(newAlignment);
    };
    const handleChangeUploadImage = (event) => {
        setGovtIdImage(event.target.files[0]);
    }
    const handleEmployeeImage = (event) => {
        setEmployeeImage(event.target.files[0]);
    }
    const handleAccordionClick = (accordionId) => {
        setOpenAccordion((prevOpenAccordion) =>
            prevOpenAccordion === accordionId ? null : accordionId
        );
    };

    const validSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().required('Email is required'),
        mobileNum: Yup.string().required('Mobile No. is required'),
        password: Yup.string().required('Password is required'),
        description: Yup.string().required('Bio is required'),
        address1: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zipcode: Yup.string().required('Zipcode Code is required'),
        country: Yup.string().required('Country is required'),
        role: Yup.string().required('Roles is required'),
        shiftId: Yup.string().required('Shift is required'),
        officeLocation: Yup.string().required('Office location is required'),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            mobileNum: '',
            description:'',
            address1: '',
            city: '',
            state: '',
            zipcode: '',
            country: '',
            shiftId: '',
            officeLocation: '',
            role: '',
            govt_id_type: '',
        },
        validationSchema: validSchema,
        onSubmit: (data) => {
            userSubmit(data);
        }
    });

    const getShift = () => {
        const formData = new FormData();
        formData.append("location_name", 'SmartCRM');
        ConfigApi.shift(formData).then(
            (resp) => {
                if (resp.status === 200) {
                    setShiftData(resp.data.response);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
           /* alert(error);*/
           Helpers.error401(error);
        });
    };

    const getGovtIds = () => {
        ConfigApi.getGovtIdList().then(
            (resp) => {
                if (resp.status === 200) {
                    console.log("hereeeeee data ",resp.data.response);
                    setGovtIds(resp.data.response);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
           /* alert(error);*/
           Helpers.error401(error);
        });
    };
    
    const getLocation = () => {
        ConfigApi.location().then(
            (resp) => {
                if (resp.status === 200) {
                    setLocationData(resp.data.response);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }
        ).catch((error) => {
           /* alert(error);*/
           Helpers.error401(error);
        });
    };

    const getRole = () => {
        ConfigApi.role().then(
            (resp) => {
                if (resp.status === 200) {
                    setRoleData(resp.data.response);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }
            }).catch((error) => {
            /*alert(error);*/
            Helpers.error401(error);
        });
    };

    const getUserDetail = () => {
        UserApi.user(1).then(
            (resp) => {
                if (resp.status === 200) {
                    setUserData(resp.data.response.usersdetails);
                    setUserExperienceData(resp.data.response.experiencedetails);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                }}
        ).catch((error) => {
            /*alert(error);*/
            Helpers.error401(error);
        });
    };

    const convertJSONToFormData = () => {
        const newFormData = new FormData();
        newFormData.append('office_location', values.officeLocation);
        newFormData.append('password', values.password);
        newFormData.append('email', values.email);
        newFormData.append('first_name', values.firstName);
        newFormData.append('last_name', values.lastName);
        newFormData.append('phone', values.mobileNum);
        newFormData.append('role_id', values.role);
        newFormData.append('profile_img', employeeImage);
        newFormData.append('description', values.description);
        newFormData.append('shift_id', values.shiftId);
        newFormData.append('status', status);
        newFormData.append('blacklist', blacklist);
        newFormData.append('dob', fDateOnly(dob));
        newFormData.append('doj', fDateOnly(doj));
        newFormData.append('address', values.address1);
        newFormData.append('city', values.city);
        newFormData.append('state', values.state);
        newFormData.append('country', values.country);
        newFormData.append('zipcode', values.zipcode);
        newFormData.append('govtid_type_id', values.govt_id_type);
        //  newFormData.append('govtid_type_id', values.govt_id_type);
        newFormData.append('govtid', govtIdImage);
        newFormData.append('previous_user_detail', JSON.stringify(formBlocks));
        return newFormData;
    };

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const userSubmit = () => {
        const registerData = convertJSONToFormData(formik.values);
        console.log(formBlocks);
        setLoading(true);
        if(existUser){
            updateEmployee(registerData);
        }else{
            crateEmployee(registerData);
        }     
    };

    const crateEmployee = (data) => {
        UserApi.register(data).then(
            (resp) => {
                if (resp.status === 200 && resp.data.success) {
                    Helpers.toastSuccess('User created successfully');
                    console.log("response create user", resp.data.response);
                    navigate('/dashboard/profile', { replace: true});
                    formik.resetForm();
                    setBlacklist();
                    // setDob(dayjs(null));
                    //  setDoj(dayjs(null));
                    //   setEmployeeImage(null);
                    //   setGovtIdImage(null);
                    setOpenAccordion('personalInfo');
                    setStatus();
                    setFormBlocks([
                        { employer_name: '', previous_role: '', start_date: null, end_date: null, reason_of_leave: '' },
                    ]);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                    setLoading(false);
                }
            }).catch((error) => {
           /* alert(error);*/
           Helpers.error401(error);
           setLoading(false);
        });
    };
    const updateEmployee = (data) => {
        UserApi.updateEmployee(data, registerData).then(
            (resp) => {
                if (resp.status === 200 && resp.data.success) {
                    Helpers.toastSuccess('User updated successfully');
                    console.log("response create user", resp.data.response);
                    navigate('/dashboard/profile', { replace: true});
                    formik.resetForm();
                    setBlacklist();
                    // setDob(dayjs(null));
                    //  setDoj(dayjs(null));
                    //   setEmployeeImage(null);
                    //   setGovtIdImage(null);
                    setOpenAccordion('personalInfo');
                    setStatus();
                    setFormBlocks([
                        { employer_name: '', previous_role: '', start_date: null, end_date: null, reason_of_leave: '' },
                    ]);
                } else {
                    console.log("error", resp.data.error_msg);
                    alert(resp.data.error_msg);
                    setLoading(false);
                }
            }).catch((error) => {
            /*alert(error);*/
            Helpers.error401(error);
            setLoading(false);
        });
    };
    const [formBlocks, setFormBlocks] = useState([
        { employer_name: '', previous_role: '', start_date: null, end_date: null, reason_of_leave: '' },
    ]);

    const handleAddBlock = () => {
        setFormBlocks([
            ...formBlocks,
            { employer_name: '', previous_role: '', start_date: null, end_date: null, reason_of_leave: '' },
        ]);
    };

    const handleRemoveBlock = (index) => {
        const updatedBlocks = [...formBlocks];
        updatedBlocks.splice(index, 1);
        setFormBlocks(updatedBlocks);
    };

    const handleFieldChange = (index, fieldName, value) => {
        const updatedBlocks = [...formBlocks];
        updatedBlocks[index][fieldName] = value;
        setFormBlocks(updatedBlocks);
    };


    const selectLocation = (locId) => {
        setLocationId(locId);
        getShiftByLocation(locId);
    };

    const getShiftByLocation = (id) => {
        const formData = new FormData();
        formData.append("location_id", id);
        ConfigApi.shiftByLocation(formData).then(
          (resp) => {
            if (resp.status === 200) {
              console.log(resp.data.response);
              setShiftData(resp.data.response);
            } else {
              console.log("error", resp.data.error_msg);
            }
          }
        ).catch((error) => {
          Helpers.error401(error);
        });
      };


    return (
        <>
            <div style={{
                height: "100vh",
            }}>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <div className="yui">
                            <img src="/register_img.svg" style={{ "width": "34%", "objectFit": "cover", "position": "fixed",marginTop: "93px", }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7} style={{
                        padding: 6, borderTopRightRadius: 10,
                        [theme.breakpoints.down('sm')]: {
                            padding: 6, background: "#f7f7f7", borderRadius: 10,
                        },
                    }}>
                        <FormikProvider value={formik} >
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ "width": "90%", "margin": "auto", "marginTop": "0px" }}>
                                <Accordion
                                    expanded={openAccordion === 'personalInfo'}
                                    onChange={() => handleAccordionClick('personalInfo')}
                                    style={{ "boxShadow": "1px 1px 30px #dddddd", "borderRadius": "0px", "padding": "9px" }} >
                                    <AccordionSummary className="one"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography style={{ fontSize: 16, "fontFamily": "Montserrat, sans-serif", fontWeight: "600" }}>Personal Information</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={3} >
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="firstName"
                                                    type="text"
                                                    label="First Name"
                                                    variant="outlined"
                                                    {...getFieldProps('firstName')}
                                                    error={(Boolean(touched.firstName && errors.firstName))}
                                                    helperText={(touched.firstName && errors.firstName)}
                                                    style={{ fontSize: 14 }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="lastName"
                                                    type="text"
                                                    label="Last Name"
                                                    variant="outlined"
                                                    {...getFieldProps('lastName')}
                                                    error={(Boolean(touched.lastName && errors.lastName))}
                                                    helperText={(touched.lastName && errors.lastName)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    disabled={isEndUser}
                                                    type="text"
                                                    label="Email"
                                                    variant="outlined"
                                                    {...getFieldProps('email')}
                                                    error={(Boolean(touched.email && errors.email))}
                                                    helperText={(touched.email && errors.email)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    type="Password"
                                                    label="Password"
                                                    variant="outlined"
                                                    {...getFieldProps('password')}
                                                    error={(Boolean(touched.password && errors.password))}
                                                    helperText={(touched.password && errors.password)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="mobileNum"
                                                    type="text"
                                                    label="Mobile Number"
                                                    variant="outlined"
                                                    {...getFieldProps('mobileNum')}
                                                    error={(Boolean(touched.mobileNum && errors.mobileNum))}
                                                    helperText={(touched.mobileNum && errors.mobileNum)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} style={{ marginLeft: 22 }}>
                                                    <DesktopDatePicker
                                                        style={{ fontSize: "14px" }}
                                                        label="Dob"
                                                        inputFormat="MM/DD/YYYY"
                                                        value={dob}
                                                        onChange={(val) => setDob(val)}
                                                        renderInput={(params) => <TextField {...params} error={false} />}

                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    label="Bio"
                                                    variant="outlined"
                                                    {...getFieldProps('description')}
                                                    error={(Boolean(touched.description && errors.description))}
                                                    helperText={(touched.description && errors.description)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <Typography style={{ fontSize: 14, textAlign: "left" }}>Status</Typography>
                                                <ToggleButtonGroup
                                                    style={{ display: "flex" }}
                                                    color="primary"
                                                    value='web'
                                                    exclusive
                                                    onChange={handleChangeStatus}
                                                    aria-label="Platform"
                                                >
                                                    <ToggleButton style={{ backgroundColor: status === "active" ? "#f5a773" : "inherit", padding: "6px 16px" }} value="active">Active</ToggleButton>
                                                    <ToggleButton style={{ backgroundColor: status === "inactive" ? "#f5a773" : "inherit", padding: "6px 16px" }} value="inactive">Inactive</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <Typography style={{ fontSize: 14, textAlign: "left" }}>Employee Image </Typography>
                                                <input
                                                    required
                                                    style={{ marginTop: 10, textAlign: "left", }}
                                                    type="file"
                                                    accept="image/*"
                                                    //    capture="filesystem"
                                                    capture="none"
                                                    multiple
                                                    name="employeeImage"
                                                    onChange={(event) => handleEmployeeImage(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant="contained" style={{ "background": "#f60", fontSize: 12, "padding": "9px 44px", "fontWeight": "600", "float": "right" }} onClick={() => setOpenAccordion('officialInfo')}>Next</Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion
                                    expanded={openAccordion === 'officialInfo'}
                                    onChange={() => handleAccordionClick('officialInfo')}
                                    style={{ "boxShadow": "1px 1px 30px #dddddd", "borderRadius": "0px", "padding": "9px" }} >
                                    <AccordionSummary className="two"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography style={{ "fontFamily": "Montserrat, sans-serif", fontWeight: "600" }}>Offical Details</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <Grid container spacing={3} >
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="address1"
                                                    type="text"
                                                    label="Address"
                                                    variant="outlined"
                                                    {...getFieldProps('address1')}
                                                    error={(Boolean(touched.address1 && errors.address1))}
                                                    helperText={(touched.address1 && errors.address1)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="city"
                                                    type="text"
                                                    label="City"
                                                    variant="outlined"
                                                    {...getFieldProps('city')}
                                                    error={(Boolean(touched.city && errors.city))}
                                                    helperText={(touched.city && errors.city)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="state"
                                                    type="text"
                                                    label="State"
                                                    variant="outlined"
                                                    {...getFieldProps('state')}
                                                    error={(Boolean(touched.state && errors.state))}
                                                    helperText={(touched.state && errors.state)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="zipcode"
                                                    type="text"
                                                    label="zipcode"
                                                    variant="outlined"
                                                    {...getFieldProps('zipcode')}
                                                    error={(Boolean(touched.zipcode && errors.zipcode))}
                                                    helperText={(touched.zipcode && errors.zipcode)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    autoComplete="country"
                                                    type="text"
                                                    label="Country"
                                                    variant="outlined"
                                                    {...getFieldProps('country')}
                                                    error={(Boolean(touched.country && errors.country))}
                                                    helperText={(touched.country && errors.country)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DesktopDatePicker
                                                        label="DoJ"
                                                        inputFormat="MM/DD/YYYY"
                                                        value={doj}
                                                        onChange={(val) => setDoj(val)}
                                                        renderInput={(params) => <TextField {...params} error={false} />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                           
                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-multiple-name-label">Govt ID</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        input={<FilledInput label="Name" />}
                                                        {...getFieldProps('govt_id_type')}
                                                        style={{ fontSize: 14, textAlign: "left" }}
                                                        error={(Boolean(touched.govt_id_type && errors.govt_id_type))}
                                                        helperText={(touched.govt_id_type && errors.govt_id_type)}
                                                    >
                                                        {govtIds.length > 0 && govtIds
                                                        .slice() 
                                                        .sort((a, b) => a.id_name.localeCompare(b.id_name)).map((item, index) => (
                                                            <MenuItem style={{ fontSize: 14, textAlign: "left" }} key={index} value={item.id} >{item.id_name}</MenuItem>))
                                                        }

                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                            <Typography style={{ fontSize: 14, textAlign: "left" }}>Upload Document</Typography>

                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    capture="none"
                                                    multiple
                                                    name="myImage"
                                                    onChange={(event) => handleChangeUploadImage(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormControl fullWidth>
                                                <InputLabel id="demo-multiple-name-label">Location</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        input={<FilledInput label="Name" />}
                                                        {...getFieldProps('officeLocation')}
                                                        style={{ fontSize: 14, textAlign: "left" }}
                                                        error={(Boolean(touched.officeLocation && errors.officeLocation))}
                                                        helperText={(touched.officeLocation && errors.officeLocation)}                                                        
                                                    >
                                                        {locationData.length > 0 && locationData?.map((item, index) => (
                                                            <MenuItem style={{ fontSize: 14, textAlign: "left" }} key={index} value={item.office_location_id} onClick={() => selectLocation(item.office_location_id)}>{item.name}</MenuItem>))
                                                        }
                                                    </Select>
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>

                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-multiple-name-label">Role</InputLabel>
                                                    <Select
                                                        disabled={isEndUser}
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        input={<FilledInput label="Name" />}
                                                        {...getFieldProps('role')}
                                                        style={{ fontSize: 14, textAlign: "left" }}
                                                        error={(Boolean(touched.role && errors.role))}
                                                        helperText={(touched.role && errors.role)}
                                                    >
                                                        {roleData.length > 0 && 
                                                        roleData
                                                        .slice() 
                                                        .sort((a, b) => a.role_name.localeCompare(b.role_name))
                                                        .map((item, index) => (
                                                          <MenuItem style={{ fontSize: 14, textAlign: "left" }} key={index} value={item.id}>
                                                            {item.role_name}
                                                          </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                           

                                            <Grid item xs={12} sm={6} md={6}>
                                                <FormControl fullWidth>
                                                <InputLabel id="demo-multiple-name-label">Shift</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        id="demo-multiple-name"
                                                        input={<FilledInput label="Name" />}
                                                        {...getFieldProps('shiftId')}
                                                        style={{ fontSize: 14, textAlign: "left" }}
                                                        error={(Boolean(touched.shiftId && errors.shiftId))}
                                                        helperText={(touched.shiftId && errors.shiftId)}
                                                        >
                                                        {shiftData.length > 0 && shiftData?.map((item, index) => (
                                                            <MenuItem style={{ fontSize: 14, textAlign: "left" }} key={index} value={item.id} >{`${item.shift_name}  (${item.start_time}-${item.end_time})`}</MenuItem>))
                                                        }
                                                    </Select>
                                                </FormControl>

                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <Typography style={{ fontSize: 14, textAlign: "left" }}>Status</Typography>
                                                <ToggleButtonGroup
                                                    style={{ display: "flex" }}
                                                    color="primary"
                                                    value={blacklist}
                                                    exclusive
                                                    onChange={handleChange}
                                                    aria-label="Platform"
                                                >
                                                    <ToggleButton style={{ backgroundColor: blacklist === "0" ? "#f5a773" : "inherit", padding: "6px 16px" }} value="0">No</ToggleButton>
                                                    <ToggleButton style={{ backgroundColor: blacklist === "1" ? "#f5a773" : "inherit", padding: "6px 16px" }} value="1">Blacklist</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant="contained" style={{ "background": "#f60", fontSize: 12, "padding": "9px 44px", "fontWeight": "600", "float": "right" }} onClick={() => setOpenAccordion('prevService')}>Next</Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion
                                    expanded={openAccordion === 'prevService'}
                                    onChange={() => handleAccordionClick('prevService')}
                                    style={{ "boxShadow": "1px 1px 30px #dddddd", "borderRadius": "0px", "padding": "9px" }} >

                                    <AccordionSummary className="three" expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                                        <Typography style={{ "fontFamily": "Montserrat, sans-serif", fontWeight: "600" }}>Previous Service Record</Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <Box display="flex" flexDirection={'column'} justifyContent={"center"} margin="auto" borderRadius={2}>
                                            <div>
                                                <Grid item xs={16} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <IconButton onClick={handleAddBlock} style={{ marginRight: "-7px" }}>
                                                        <AddIcon style={{ color: "#4f74ff" }} />
                                                    </IconButton>
                                                </Grid>
                                                {formBlocks.map((block, index) => (
                                                    <FormBlock
                                                        key={index}
                                                        index={index}
                                                        data={block}
                                                        onChange={handleFieldChange}
                                                        onRemove={handleRemoveBlock}
                                                    />
                                                ))}
                                            </div>

                                            <Grid className={Helpers.cmAccess('EDIT_EMPLOYEE_SUBMIT')} item xs={16}>
                                                <LoadingButton
                                                    spacing={3}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    // loading={isSubmitting}
                                                    style={{ "background": "#f60", fontSize: 12, width: "auto", "padding": "9px 44px", "fontWeight": "600", "float": "right" }} onClick={() => setOpenAccordion('prevService')}
                                                >
                                                    Submit
                                                </LoadingButton>
                                            </Grid>
                                        </Box>
                                    </AccordionDetails>

                                </Accordion>
                            </Form>
                        </FormikProvider>
                <div className='loader'>
          {loading ? (
            <CircularProgress style={{color:'#ED9F2A'}}/>
          ) : ''}
        </div>
                    </Grid>
                </Grid>

            </div>
        </>
    );
}

function FormBlock({ index, data, onChange, onRemove }) {
    const { employer_name, previous_role, start_date, end_date, reason_of_leave } = data;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
                <TextField
                    fullWidth
                    autoComplete="employer_name"
                    type="text"
                    label="Employer Name"
                    variant="outlined"
                    value={employer_name}
                    onChange={(e) => onChange(index, 'employer_name', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <TextField
                    fullWidth
                    autoComplete="previous_role"
                    type="text"
                    label="Role"
                    variant="outlined"
                    value={previous_role}
                    onChange={(e) => onChange(index, 'previous_role', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Start Date"
                        inputFormat="MM/DD/YYYY"
                        value={start_date}
                        onChange={(date) => onChange(index, 'start_date', fDateOnly(date))}
                        renderInput={(params) => <TextField {...params} error={false} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="End Date"
                        inputFormat="MM/DD/YYYY"
                        value={end_date}
                        onChange={(date) => onChange(index, 'end_date', fDateOnly(date))}
                        renderInput={(params) => <TextField {...params} error={false} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    autoComplete="reason_of_leave"
                    type="text"
                    label="Reason Of Leaving"
                    variant="outlined"
                    value={reason_of_leave}
                    onChange={(e) => onChange(index, 'reason_of_leave', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <IconButton onClick={() => onRemove(index)} style={{ float: "right" }}>
                    <DeleteOutlineTwoToneIcon style={{ color: "red" }} />
                </IconButton>
            </Grid>
        </Grid>
    );
}